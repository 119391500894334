import React, {useState, useEffect} from 'react'
import Layout from '../components/layout'
import HubspotForm from "react-hubspot-form"
import AOS from 'aos';
import "aos/dist/aos.css";

const EmployeeRecognitionProgramV2 = ({ location }) => {
    const [vidWidth, setvidWidth] = useState('')

    const checkScreenWidth = () => {
        if(typeof window !== "undefined"){
            if(window.innerWidth <= 415) setvidWidth((90 * window.innerWidth)/100)

            else if(window.innerWidth <= 768) setvidWidth((70 * window.innerWidth)/100)
            
            else if(window.innerWidth <= 1466) setvidWidth((40 * window.innerWidth)/100)
            
            else setvidWidth((30 * window.innerWidth)/100)
        }
    }

    useEffect(() => {
        checkScreenWidth()
    }, [])
    
    useEffect(() => {
        AOS.init({
            once:true
        });
      }, []);

  return (
    <Layout location={location}>
        <section class="w-full flex place-content-center relative bg-indigo-100 overflow-x-hidden py-9 xl:py-0 overflow-y-hidden z-0 px-3 lg:px-0">
            <div class="max-w-7xl flex flex-col xl:flex-row justify-center items-start z-10 lg:px-3">
                <div class="w-full xl:w-8/12 flex flex-col text-center lg:text-left justify-center xl:items-start items-center lg:mr-3 lg:pt-20">
                    {/* <div class="my-2 flex justify-center items-center px-4 py-2 rounded-full border border-gray-darklight bg-indigo-50">
                        <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Rewards-ico-hero.png" alt="Vantage Pulse" height="15" width="15" />
                        <h2 class="text-purple-100 text-sm lato"><span class="font-bold">&nbsp;&nbsp;VANTAGE </span>REWARDS</h2>
                    </div> */}

                    <h1 class="text-purple-100 bannerSectionTitle-3 lg:pr-3 text-center xl:w-11/12">An <span className= "text-orange">Employee Recognition</span> program for all cultures and generations!</h1>


                    <p class="my-8 text-lg xl:text-xl text-purple-100 md:w-10/12">Automate your Employee Recognition initiatives with our easy-to-use, flexible Rewards and Recognition platform.</p>
                    <div class="grid justify-center items-center lg:justify-start w-5/6 md:w-1/2 lg:w-full xl:w-9/12 2xl:w-2/3 cursor-pointer">
                        <a class="vc-new-orange-btn-rounded-full lato rounded-full" href="#form">Start your recognition journey</a>
                    </div>
                </div>        
                <div class="flex justify-center items-center mt-6 w-full xl:w-5/12 lg:ml-3" data-aos="fade-up" data-aos-duration="500" data-aos-delay= "600"><img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/hero.webp" alt="Vantage Rewards" /></div>
            </div>
        </section>
        <section className='w-full flex justify-center items-center lg:pb-5 px-5 xl:px-0 overflow-hidden pb-8'>
            <div className='xl:flex lg:max-w-7xl py-5 lg:py-0'>
                <div className='xl:w-7/12 pt-10 xl:py-24 lg:pr-3'>
                    <h2 className='text-3xl lg:text-4xl xl:text-5xl font-bold xl:text-left text-center'>Align corporate goals with <span className='text-orange'>employee priorities</span></h2>
                    <p className='pt-6 xl:py-9 text-xl text-center xl:text-left text-gray-500 md:w-10/12 xl:w-10/12 mx-auto xl:mx-0'>Overcome low morale and high turnover in employees with our comprehensive Employee Rewards programs.</p>  
                </div>
                <div className='lg:w-4/12 xl:py-10 grid xl:justify-end items-start relative'>
                    <div className= "grid md:flex justify-center transform -translate-x-2 my-2 xl:my-0 lg:-translate-x-0 lg:py-0 2xl:pb-16">
                        <img src='' />
                    </div>
                    <div className='grid grid-cols-2 md:grid-cols-3 gap-5 xl:gap-40 py-5 xl:py-0 2xl:py-5 xl:ml-10 z-10'>
                        <div className='p-5 rounded-lg shadow flex flex-col justify-center items-center bg-white' style={{ width: '10rem', height: '10rem' }} data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "400">
                            <img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Group-13-3.webp' width="50"/>
                            <p className='text-center text-gray-500 font-bold mt-5 text-sm'>Peer-to-peer Recognition</p>
                        </div>
                        <div className='p-5 rounded-lg shadow flex flex-col justify-center items-center bg-white' style={{ width: '10rem', height: '10rem' }} data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "500">
                            <img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Group-11.webp' width="50"/>
                            <p className='text-center text-gray-500 font-bold mt-5 text-sm'>Life Events</p>
                        </div>
                        <div className='p-5 rounded-lg shadow flex flex-col justify-center items-center bg-white' style={{ width: '10rem', height: '10rem' }} data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "600">
                            <img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Group-7.webp' width="50"/>
                            <p className='text-center text-gray-500 font-bold mt-5 text-sm'>E-Gift Cards</p>
                        </div>
                        <div className='xl:hidden p-5 rounded-lg shadow flex flex-col justify-center items-center bg-white' style={{ width: '10rem', height: '10rem' }} data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "700">
                            <img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Group-13-2.webp' width="50"/>
                            <p className='text-center text-gray-500 font-bold mt-5 text-sm'>Amazon Store</p>
                        </div>
                        <div className='xl:hidden p-5 rounded-lg shadow flex flex-col justify-center items-center bg-white' style={{ width: '10rem', height: '10rem' }} data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "800">
                            <img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Group-13.webp' width="50"/>
                            <p className='text-center text-gray-500 font-bold mt-5 text-sm'>Experiences</p>
                        </div>
                        <div className='xl:hidden p-5 rounded-lg shadow flex flex-col justify-center items-center bg-white' style={{ width: '10rem', height: '10rem' }} data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "900">
                            <img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Group-16.webp' width="50"/>
                            <p className='text-center text-gray-500 font-bold mt-5 text-sm'>Service Milestone</p>
                        </div>
                    </div>

                    <div className='xl:grid grid-cols-2 xl:grid-cols-3 gap-5 xl:gap-32 py-5 xl:py-0 2xl:py-5 hidden z-10'>
                        <div className='p-5 rounded-lg shadow flex flex-col justify-center items-center bg-white' style={{ width: '10rem', height: '10rem' }} data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "700">
                            <img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Group-13-2.webp' width="50"/>
                            <p className='text-center text-gray-500 font-bold mt-5 text-sm'>Amazon Store</p>
                        </div>
                        <div className='p-5 rounded-lg shadow flex flex-col justify-center items-center bg-white' style={{ width: '10rem', height: '10rem' }} data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "800">
                            <img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Group-13.webp' width="50"/>
                            <p className='text-center text-gray-500 font-bold mt-5 text-sm'>Experiences</p>
                        </div>
                        <div className='p-5 rounded-lg shadow flex flex-col justify-center items-center bg-white' style={{ width: '10rem', height: '10rem' }} data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "900">
                            <img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Group-16.webp' width="50"/>
                            <p className='text-center text-gray-500 font-bold mt-5 text-sm'>Service Milestone</p>
                        </div>
                    </div>
                    <div className='absolute -top-20 -right-52 bg-purple-150 opacity-20 rounded-full z-0' style = {{ height: '600px', width: '600px' }}>
                        <img src='' />
                    </div>
                </div>
            </div>
        </section>
        <section class="w-full flex place-content-center relative overflow-x-hidden py-0 lg:py-8 overflow-y-hidden rewards-bg-1 px-3 lg:px-0" id="FormVR">
            <div class="lg:flex max-w-7xl px-3">
                <div class="card-1 w-full mb-10 md:mb-0 mt-5 xl:mt-0 lg:w-1/2 xl:w-2/3 2xl:w-2/3">
                    <h2 class="text-3xl xl:text-5xl text-center xl:text-left text-gray-250 font-bold pt-5 mb-8 md:px-12 lg:px-0 xl:w-8/12">Making Life Easier for <span class="relative">HR<img decoding="async" loading="lazy" class="absolute -bottom-1 right-0 xl:right-0" src="https://res.cloudinary.com/vantagecircle/image/upload/v1648619895/gatsbycms/uploads/2022/03/hero-strokesvg.svg" /></span></h2>
                    <p class="mb-8 text-center text-xl md:text-left mt-2 mb-4 xl:w-8/12 text-gray-500">
                        Quick glimpse into our Rewards & Recognition program.
                    </p>
                    <div className='mt-12 mb-8'>
                        <div className='flex w-full items-center'>
                            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/dashboard-interface-copy-4.png" width= "46" height="20" />
                            <p className='m-0 pl-5 text-gray-500 text-xl'>Intuitive Admin Dashboard For Complete Control</p>
                        </div>
                    </div>
                    <div className='my-8'>
                        <div className='flex w-full items-center'>
                            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/AUtomated-recognition.png" width= "46" height="20" />
                            <p className='m-0 pl-5 text-gray-500 text-xl'>Automated Recognition For Birthdays And Work Anniversaries</p>
                        </div>
                    </div>
                    <div className='my-8'>
                        <div className='flex w-full items-center'>
                            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/Announcements.png" width= "46" height="20" />
                            <p className='m-0 pl-5 text-gray-500 text-xl'>Company Wide Announcements</p>
                        </div>
                    </div>
                    <div className='my-8'>
                        <div className='flex w-full items-center'>
                            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/Awards-Badges.png" width= "46" height="20" />
                            <p className='m-0 pl-5 text-gray-500 text-xl'>Customized Awards & Badges</p>
                        </div>
                    </div>
                    <div className='my-8'>
                        <div className='flex w-full items-center'>
                            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/Rewards-Catalog.png" width= "46" height="20" />
                            <p className='m-0 pl-5 text-gray-500 text-xl'>Global Rewards Catalog</p>
                        </div>
                    </div>
                    <div className='my-8'>
                        <div className='flex w-full items-center'>
                            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/15-Languages.png" width= "46" height="20" />
                            <p className='m-0 pl-5 text-gray-500 text-xl'>Available In 15+ Languages</p>
                        </div>
                    </div>
                    <div className='my-8'>
                        <div className='flex w-full items-center'>
                            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/CUstomer-support.png" width= "46" height="20" />
                            <p className='m-0 pl-5 text-gray-500 text-xl'>Best-In-Class Customer Support</p>
                        </div>
                    </div>
                </div>
                <div className='lg:w-1/2 xl:w-5/12 2xl:w-1/3 flex items-center'>
                    <div id="form" className="card-1 w-full mb-10 md:mb-0 mt-10 xl:mt-0 flex flex-col place-items-center rounded-lg px-6 py-10 rewards-bg-2">
                        <h2 class="text-2xl lato text-gray-250 pt-5 mb-8 md:px-12 lg:px-0 text-center">
                            Start your journey towards fostering a dynamic culture of appreciation
                        </h2>
                        <p class="text-sm mb-8 text-center mt-2">
                            Get a Personalized walkthrough of Vantage Rewards from a friendly member of our team
                        </p>
                        <div class="w-full flex justify-center items-center lato rewards">
                            <HubspotForm
                            portalId="6153052"
                            formId="11213e6e-d35f-4b35-9324-95fbd5292961"
                            onSubmit={() => console.log("Submit!")}
                            onReady={form => console.log("Form ready!")}
                            loading={<div>Loading...</div>}
                            />
                        </div>
                        <p class="text-sm text-center">
                            We safeguard your{" "}
                            <a href="/privacy-policy/" class="text-orange">
                            Privacy
                            </a>
                        </p>
                        <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"></script>
                    </div>
                </div>
            </div>
        </section>
        <section className='py-5'>
            <div className='max-w-7xl mx-auto px-5'>
                <h2 className='text-gray-250 text-3xl xl:text-5xl max-w-3xl mx-auto mt-10 mb-5 font-bold text-center'>Retain top talent with our exclusive <span className='text-orange'>features</span></h2>
                <div className='grid xl:grid-cols-3 gap-5 xl:gap-16 xl:mt-20 xl:mb-10'>
                    <div className='my-5 flex-col justify-center items-center xl:my-0' data-aos="fade-up" data-aos-duration="500" data-aos-delay= "300">
                        <div className='w-full'><img className= 'py-4 mx-auto' src= "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Group-15-1.webp" width="80"/></div>
                        <h2 className='py-2 text-2xl text-center'>Instant Recognition</h2>
                        <p className='py-2 text-center px-3 xl:px-0 text-gray-250'>Experience the power of instant recognition with our innovative platform.</p>
                    </div>
                    <div className='my-5 xl:my-0' data-aos="fade-up" data-aos-duration="500" data-aos-delay= "400">
                        <div className='w-full'><img className= 'py-4 mx-auto' src= "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Group-19-1.webp" width="80"/></div>
                        <h2 className='py-2 text-2xl text-center'>Culture of Appreciation</h2>
                        <p className='py-2 text-center px-3 xl:px-0 text-gray-250'>Cultivate a culture of appreciation at your company.</p>
                    </div>
                    <div className='my-5 xl:my-0' data-aos="fade-up" data-aos-duration="500" data-aos-delay= "500">
                        <div className='w-full'><img className= 'py-4 mx-auto' src= "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Group-14-1.webp" width="80"/></div>
                        <h2 className='py-2 text-2xl text-center'>Long Service Award</h2>
                        <p className='py-2 text-center px-3 xl:px-0 text-gray-250'>Recognize individuals' substantial contributions over time.</p>
                    </div>
                    <div className='my-5 xl:my-0' data-aos="fade-up" data-aos-duration="500" data-aos-delay= "600">
                        <div className='w-full'><img className= 'py-4 mx-auto' src= "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Group-11-1.webp" width="80"/></div>
                        <h2 className='py-2 text-2xl text-center'>Personalized Awards</h2>
                        <p className='py-2 text-center px-3 xl:px-0 text-gray-250'>Improve Employee Engagement and Productivity with our Innovative Employee Award Programs.</p>
                    </div>
                    <div className='my-5 xl:my-0' data-aos="fade-up" data-aos-duration="500" data-aos-delay= "700">
                        <div className='w-full'><img className= 'py-4 mx-auto' src= "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Group-8.webp" width="80"/></div>
                        <h2 className='py-2 text-2xl text-center'>Rewards Redemption</h2>
                        <p className='py-2 text-center px-3 xl:px-0 text-gray-250'>With multiple redemption options and a user-friendly platform, you can claim your rewards quickly and effortlessly.</p>
                    </div>
                    <div className='my-5 xl:my-0' data-aos="fade-up" data-aos-duration="500" data-aos-delay= "800">
                        <div className='w-full'><img className= 'py-4 mx-auto' src= "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Group-7-1.webp" width="80"/></div>
                        <h2 className='py-2 text-2xl text-center'>Super Quick Integration</h2>
                        <p className='py-2 text-center px-3 xl:px-0 text-gray-250'>Seamless integrations with your existing HCM/HRIS platform and single-sign-on (SSO) tools.</p>
                    </div>
                </div>
                <div className='w-10/12 xl:w-1/3 flex justify-center pb-10 mx-auto'><a href="#form" className='text-orange text-center vc-colored-btn'>Start your recognition journey</a></div>
            </div>
        </section>  
        <section className='bg-indigo-100 w-full flex justify-center py-10 px-5'>
            <div className='max-w-7xl xl:flex'>
                <div className=''>
                    <h2 className='text-3xl xl:text-5xl text-center xl:text-left text-purple-100 font-bold pt-5 mb-8 md:px-12 xl:px-0 xl:w-9/12'>Vantage Circle is trusted by <span className='text-orange'>700+</span> global companies</h2>
                    <p className='text-purple-100 xl:w-8/12 text-center xl:text-left'>See how organizations are using Vantage Circle to build an engaged and productive workplace</p>
                    <div class="grid justify-center items-center lg:justify-start w-full md:w-1/2 lg:w-full xl:w-9/12 2xl:w-2/3 cursor-pointer py-5">
                        <a class="vc-new-orange-btn-rounded-full lato rounded-full" href="#form">Get started</a>
                    </div>
                </div>
                <div className='xl:w-7/12 my-5'>
                    <div className='bg-white rounded flex px-7 py-5 w-full my-5' data-aos="fade-up" data-aos-duration="600" data-aos-delay= "300">
                        <div className='w-3/12 flex items-center'><img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Bitmap-2-1.png" alt="Infosys Logo" width= "70" /></div>
                        <p className='text-left w-9/12 flex items-center m-0 pl-5 text-gray-250'>L&T Infotech increased its appreciation by 10.3x</p>
                    </div>
                    <div className='bg-white rounded flex px-7 py-5 w-full my-5' data-aos="fade-up" data-aos-duration="600" data-aos-delay= "500">
                        <div className='w-3/12 flex items-center'><img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Bitmap-1.png" alt="Infosys Logo" width= "80" /></div>
                        <p className='text-left w-9/12 flex items-center m-0 pl-5 text-gray-250'>ACG saw a 66% rise in Peer-to-Peer Recognition</p>
                    </div>
                    <div className='bg-white rounded flex px-7 py-5 w-full my-5' data-aos="fade-up" data-aos-duration="600" data-aos-delay= "500">
                        <div className='w-3/12 flex items-center'><img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Bitmap-3-1.png" alt="Infosys Logo" width= "100" /></div>
                        <p className='text-left w-9/12 flex items-center m-0 pl-5 text-gray-250'>Tata Realty saw 40% growth in employee engagement</p>
                    </div>
                </div>
            </div>
        </section>   
        <section className='w-full flex flex-col justify-center items-center px-5 py-10'>
            <div className='max-w-7xl my-5'>
                <h2 className='text-gray-250 text-3xl xl:text-5xl xl:max-w-4xl mx-auto xl:mt-10 mb-5 font-bold text-center'>Winner of top global awards in Employee Recognition and Engagement</h2>
                <div className='grid md:grid-cols-2 xl:grid-cols-3 py-5 gap-2 items-center justify-center'>
                    <div className='flex justify-center my-5'><img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1628664737/gatsbycms/uploads/2023/02/G2-1.webp" alt="G2 award4" width="130" height="125" /></div>
                    <div className='flex justify-center my-5'><img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1628664738/gatsbycms/uploads/2023/02/Bakers-Dozen-1.webp" alt="Baker's Dozen award" width="190" height="100" /></div>
                    <div className='flex justify-center my-5'><img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1629548690/gatsbycms/uploads/2023/02/Brandon-Hall-1.webp" alt="Brandon hall award" width="190" height="110" /></div>
                </div>
            </div>
            <div className='max-w-7xl my-5'>
                <h2 className='text-gray-250 text-3xl xl:text-5xl xl:max-w-4xl mx-auto mt-10 mb-5 font-bold text-center'>Vantage Circle is trusted by <span className='text-orange'>700+</span> global companies</h2>
                {/* <div className='grid md:grid-cols-2 xl:grid-cols-4 py-5 gap-5 items-center justify-center my-9'>
                    <div className='flex justify-center' data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "300"><img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/10/TA_Digital-logo.png" alt="TA_Digital Logo" width="140" /></div>
                    <div className='flex justify-center' data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "400"><img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/v1560572317/vantagecircle/corporate-logos/piramal.png" alt="Parimal Logo" width="140" /></div>
                    <div className='flex justify-center' data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "700"><img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/v1560572317/vantagecircle/corporate-logos/wipro.png" alt="Wipro Logo" width="100" /></div>
                    <div className='flex justify-center' data-aos="zoom-in" data-aos-duration="500" data-aos-delay= "1000"><img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/v1560572317/vantagecircle/corporate-logos/landmark.png" alt="Landmark Logo" width="140" /></div>
                </div> */}
                <div class="c-logo-strip w-full py-10 my-8 md:my-10 lg:my-16 2xl:my-20"></div>
                {/* <a href="/corporates/" class="text-orange font-bold flex flex justify-center text-xl items-center mb-6 ">See more clients <svg class="w-6 h-6 top-0" viewBox="0 0 24 24" width="24" height="24"><polygon fill="currentColor" points="15 12 9 16 9 8"></polygon></svg></a> */}
            </div>
        </section>
        <section className='w-full bg-indigo-100 px-3 flex flex-col items-center justify-center py-6'>
            <div className='xl:grid grid-cols-2 gap-10 justify-center items-center max-w-7xl mt-20'>
                <div className='px-3 xl:pl-5'>
                    <div className='flex justify-start w-full'><h2 className='text-3xl text-left md:text-center w-full xl:text-left md:text-4xl pb-8 xl:pb-3 text-purple-100'>Product Video</h2></div>
                    <div className='py-3 rounded-md'><iframe className='mx-auto xl:mx-0 rounded-md' width={vidWidth} height="300" src="https://www.youtube.com/embed/5Y_v1fXaPz0" title="Vantage Rewards – Employee Rewards and Digital Gifting Platform | Employee Benefits" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
                </div>
                <div className='px-3 xl:border-l border-white xl:pl-10 flex flex-col justify-start pt-10 xl:pt-0'>
                    <h2 className='text-3xl text-left md:text-center xl:text-left md:text-4xl text-purple-100 xl:pt-0 pt-5 xl:pb-3'>Grow with us</h2>
                    <div className='flex flex-col justify-center' style = {{height: '300px'}}>
                        <p className='text-purple-100 text-xl md:text-2xl text-left md:text-center xl:text-left'>Join the bandwagon and deliver a satisfying employee experience with our global employee engagement and wellness platform.</p>
                        <div className='flex pt-10 w-full'>
                            <div className='w-5/12 xl:w-3/12 flex flex-col items-center xl:items-start'>
                                <h2 className='text-4xl xl:text-6xl text-orange'>700+</h2>
                                <p className='text-purple-100'>Companies</p>
                            </div>
                            <div className='w-7/12 xl:w-9/12 pl-3 xl:pl-10 border-l border-purple-100 flex flex-col items-center xl:items-start'>
                                <h2 className='text-4xl xl:text-6xl text-orange'>2M+</h2>
                                <p className='text-purple-100'>Corporate Employees</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
  )
}

export default EmployeeRecognitionProgramV2